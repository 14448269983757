export const COLORS = {
    PRIMARY: '#daeac9',
    ACCENT: '#71c115',
    DARK: '#3E3E3E',
    GRAY: '#999999',
    LIGHT_GRAY: '#BBB',
    BLACK: '#000',
    WHITE: '#FBFBFB',
    LABELS: {
        NULL: '#2C640A',
        LOW: '#71C115',
        MODERATE: '#4865CB',
        HIGH: '#F6D523',
        VERY_HIGH: '#F68F23',
        EXTREME: '#F63B23',
        CRITICAL_POINTS: '#000',
    },
};
