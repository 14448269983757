<div class="chart-js-tooltip">
    <div class="arrow"></div>
    <div class="date"></div>
    <div class="row">
        <div class="label">
            <span class="color"></span>
            <span class="name"></span>
        </div>
        <div class="percentage"></div>
        <div class="value"></div>
    </div>
</div>
